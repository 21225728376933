body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.pagination {
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  list-style-type: none;
  padding: 0 5rem;
  max-width: 1000px;
}
.pagination li a {
  border-radius: 2px;
  padding: 0.4rem 0.8rem;
  cursor: pointer;
  text-decoration: none;
  user-select: none;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 900;
  transition: 100ms ease-in-out;
}
.pagination li a:hover {
  background-color: #0366d6;
  color: white;
  transition: 100ms ease-in-out;
}
.pagination li.previous a,
.pagination li.next a,
.pagination li.break a {
  text-decoration: none;

}
.pagination li.active a {
  background-color: #0366d6;
  color: white;
}
.pagination li.disabled a {
  color: grey;
}
.pagination li.disabled a:hover {
  background-color: white;
  color: grey;
}
.pagination  li.disable,
.pagination  li.disabled a {
  cursor: default;
}
