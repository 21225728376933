.App {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 50px;
  min-height: 100vh;
  margin: 0;
  flex-direction: column;
}

.inline-block {
  display: inline-block;
}

.margin-right-10 {
  margin-right: 10px;
}

.Footer {
  text-align: center;
  width: 100%;
  background-color: rgba(200, 200, 200, 0.1);
  padding: 30px 0px;
  color: black;
  margin-top: auto;
}

.fade-enter {
  opacity: 0;
  transform: translate(0, 25px);
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transform: translate(0, 0);
  transition: opacity 250ms ease-out, transform 300ms ease;
}

.fade-exit {
  opacity: 1;
  transform: translate(0, 0);
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transform: translate(0, 30px);
  transition: opacity 250ms ease-out, transform 300ms ease;
}

table th {
  text-align: left;
}

.CounterTable td, .CounterTable th {
  padding: 0px 10px;
}

.ResultsTable thead th {
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: white;
}

.ResultsTable thead th:nth-child(3) {
  position: sticky;
  left: 0;
  z-index: 4;
  background-color: white;
}

.ResultsTable tbody tr td:nth-child(3) {
  position: sticky;
  left: 0;
  z-index: 2;
}

.ResultsTable tbody tr:nth-child(2n+1) td:nth-child(3) {
  position: sticky;
  left: 0;
  z-index: 2;
}

.input-group-inline {
  display: inline-block !important;
}